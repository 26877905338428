<template>
  <div class="home">
    <header-content></header-content>
    <div class="contactUs">
      <div id="website_contact_wrap" style="width: 100%">
        <div class="banner">
          <div id="about_banner">
            <a>
              <!-- style="background:url('@/assets/img/contact_bg1.jpg') no-repeat center top" -->
              <span class="banner_img"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="contact_main wd">
        <div class="about_des tab_content on">
          <div class="cell_title">联系我们 
            <!-- <span>CONTACT US</span> -->
          </div>
          <div class="message">
            <ul>
              <li>
                <div class="over-lay"></div>
                <div class="mes-item">
                  <div class="outer">
                    <div class="line"></div>
                    <h1>招聘联系</h1>
                    <div class="imgOuter">
                      <img
                        src="https://static.web.sdo.com/shengqugames/html/img/contactUs/mail.png"
                      />
                      <div class="imgB">
                        <a href="mailto:swallow_guo@163.com" class="email"
                          >swallow_guo@163.com</a
                        >
                      </div>
                    </div>
                    <div class="text">
                      投递简历,请点击<router-link to="/join">加入我们</router-link>。<br>邮件主题：姓名+应聘职位。
                    </div>
                  </div>
                </div>
              </li>
              <li class="last">
                <div class="over-lay"></div>
                <div class="mes-item">
                  <div class="outer">
                    <div class="line"></div>
                    <h1>客服电话</h1>
                    <div class="head1"></div>
                    <div class="imgOuter tell">
                      <img
                        src="https://static.web.sdo.com/shengqugames/html/img/contactUs/phone.png"
                      />
                      <div class="imgB">(0512)87806888</div>
                    </div>
                    <div class="text">
                      更多服务点击进入<a
                        href="/"
                        target="_blank"
                        >雄霸官网</a
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>

<script>
import HeaderContent from "@/components/HeaderContent.vue";
import FooterContent from "@/components/FooterContent.vue";
import { isMobile } from '@/utils/util'
export default {
  name: "Contact",
  components: {
        HeaderContent,
    FooterContent
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    if (isMobile()) {
      // alert("手机端");
      this.$router.replace('/contactm');
    } else {
      // alert("pc端");
      // this.$router.replace('/');
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped >
@import "../assets/css/index.css";
.banner_img {
  background: url("../assets/img/contact_bg1.jpg") no-repeat center top;
}

</style>